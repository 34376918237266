import DropdownOption from "components/Common/DropdownOption";
import en from "date-fns/locale/en-US";
import ko from "date-fns/locale/ko";
import {
  BUSINESS_LANG,
  INQUIRY_STATUS_DETAIL_PAGE,
  PUBLIC_INQUIRY_LANG,
  PUBLIC_INQUIRY_TYPE,
  TYPE_TRANSMISSION_FORMAT__MEDIA_OPTIONS,
} from "helpers/constans";
import React, { useEffect, useState } from "react";
import { registerLocale } from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";
import {
  Card,
  CardBody,
  Col,
  Container,
  Label,
  Row,
  Spinner,
} from "reactstrap";
import { catchAsync, formatDate } from "helpers/general";
import { toast, ToastContainer } from "react-toastify";
import inquiryApi from "api/inquiryApi";
import { Option } from "api/types/_public";
import { useSelector } from "react-redux";
import {
  CONFIG_OPTION_TOAST_ERROR,
  CONFIG_OPTION_TOAST_NORMAL,
} from "common/toast";

export interface Tag {
  id: string;
  text: string;
}

registerLocale("en", en);
registerLocale("ko", ko);

const HomePageInquiryDetail = () => {
  const { t, i18n } = useTranslation();
  const navigate = useNavigate();
  const { id } = useParams();
  const user: any = useSelector((state: any) => state.Login);
  const [loadingReply, setLoadingReply] = useState<boolean>(false);
  const [textReply, setTextReply] = useState<string>("");
  const [inquiryDetail, setInquiryDetail] = useState<any>();
  const [statusDetailPage, setStatusDetailPage] = useState<Option | null>();

  const INQUIRY_STATUS_DETAIL_PAGE_OPTIONS = INQUIRY_STATUS_DETAIL_PAGE?.map(
    (item: any) => ({
      value: item?.value,
      label: t(item?.label),
    })
  );

  const handleSubmitReply = (params: any) => async () => {
    setLoadingReply(true);
    if (!inquiryDetail?.id) {
      setLoadingReply(false);
      return;
    }
    try {
      const [err, result] = await catchAsync(
        inquiryApi.updateReplyHomePage({
          id: inquiryDetail?.id,
          body: params,
        })
      );

      if (result?.statusCode === 200) {
        toast.success(t("inquiry.status_has_changed"), {
          position: "top-center",
        });

        // onCloseClick();
        // paging();
        // getSummaries();
        getInquiryDetail(id);
        // setTextReply("");
      }
      if (err) {
        console.log(err);
        return;
      }
    } catch (error: any) {
      toast(error?.message || error, CONFIG_OPTION_TOAST_ERROR);
    } finally {
      setLoadingReply(false);
    }
  };

  // const renderService = (service_type: number) => {
  //   let tagName: string = "";
  //   const servicesKeyType = new Map(
  //     TYPE_TRANSMISSION_FORMAT__MEDIA_OPTIONS.map((i) => [i.value, i.label])
  //   );
  //   tagName += `${`<div class='tag-service bg-${servicesKeyType.get(
  //     service_type.toString()
  //   )}'  >${
  //     servicesKeyType.get(service_type.toString()) === "Presscon"
  //       ? "Prc"
  //       : servicesKeyType.get(service_type.toString())
  //   } </div>`}`;
  //   return (
  //     <div
  //       className="mx-auto"
  //       style={{
  //         width: "fit-content",
  //       }}
  //       dangerouslySetInnerHTML={{ __html: tagName }}
  //     />
  //   );
  // };

  const getInquiryDetail = async (id: any) => {
    const [err, result] = await catchAsync(
      inquiryApi.getHomepageDetail(id, {})
    );
    if (err) {
      toast.error(err);
      return;
    };
    setInquiryDetail(result?.data);
    setTextReply(result?.data?.reply || "");
    setStatusDetailPage(
      INQUIRY_STATUS_DETAIL_PAGE_OPTIONS?.filter(
        (i) => i?.value == result?.data?.status
      )?.map((item: any) => ({
        value: item?.value,
        label: t(item?.label),
      }))?.[0]
    );
  };

  useEffect(() => {
    if (id) {
      getInquiryDetail(id);
    }
  }, [id]);

  useEffect(() => {
    // document.title = `Super Admin`;
    document.body.classList.remove("vertical-sidebar-enable");
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [i18n?.language]);

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <div className="d-flex align-items-center gap-2 mb-3">
            <button
              onClick={() => navigate(-1)}
              type="button"
              className="btn btn-icon btn-topbar btn-ghost-secondary rounded-circle"
            >
              <i className={"bx bx-arrow-back fs-22"}></i>
            </button>
            <h4 className="mb-sm-0">
              {t("inquiry.menu_title")} | {t("inquiry.menu_detail")}
            </h4>
          </div>
          <Card id="leadsList">
            <CardBody className="pt-3">
              <div>
                {inquiryDetail?.inquiry_type === PUBLIC_INQUIRY_TYPE.SERVICE ? (
                  <div
                    className="d-flex"
                    style={{ gap: "11px", flexDirection: "column" }}
                  >
                    <Row>
                      <Col sm={12} md={5} lg={5}>
                        <div className="d-flex gap-4 align-items-center">
                          <Label
                            className="form-label mb-0"
                            style={{ minWidth: "100px" }}
                          >
                            {t("inquiry.Created_At")}
                          </Label>
                          <p className="mb-0">
                            {formatDate(
                              inquiryDetail?.created_at,
                              "YYYY.MM.DD HH:mm:ss"
                            )}
                          </p>
                        </div>

                        <div className="d-flex gap-4 align-items-center mt-2">
                          <Label
                            className="form-label mb-0"
                            style={{ minWidth: "100px" }}
                          >
                            {t("inquiry.Inquiry_type")}
                          </Label>
                          <div className="mb-0">
                            {t(
                              PUBLIC_INQUIRY_LANG[inquiryDetail?.inquiry_type]
                            )}
                          </div>
                        </div>

                        <div className="d-flex gap-4 align-items-center mt-2">
                          <Label
                            className="form-label mb-0"
                            style={{ minWidth: "100px" }}
                          >
                            {t("inquiry.Media_name")}
                          </Label>
                          <p className="mb-0">{inquiryDetail?.company_name}</p>
                        </div>

                        <div className="d-flex gap-4 align-items-center mt-2">
                          <Label
                            className="form-label mb-0"
                            style={{ minWidth: "100px" }}
                          >
                            {t("inquiry.manager")}
                          </Label>
                          <p className="mb-0">{inquiryDetail?.contact_name}</p>
                        </div>

                        <div className="d-flex gap-4 align-items-center mt-2">
                          <Label
                            className="form-label mb-0"
                            style={{ minWidth: "100px" }}
                          >
                            {t("inquiry.email")}
                          </Label>
                          <p className="mb-0">{inquiryDetail?.email}</p>
                        </div>

                        <div className="d-flex gap-4 align-items-center mt-2">
                          <Label
                            className="form-label mb-0"
                            style={{ minWidth: "100px" }}
                          >
                            {t("inquiry.domain")}
                          </Label>
                          <p className="mb-0">{inquiryDetail?.domain || "-"}</p>
                        </div>
                      </Col>

                      <Col sm={12} md={7} lg={7}>
                        <div className="d-flex gap-4 align-items-center">
                          <Label
                            className="form-label mb-0"
                            style={{ minWidth: "100px" }}
                          >
                            {t("inquiry.Status")}
                          </Label>
                          <DropdownOption
                            name="status"
                            dataList={INQUIRY_STATUS_DETAIL_PAGE_OPTIONS || []}
                            className="search-filter-category-type"
                            classNamePrefix="name-prefix"
                            initialValue={statusDetailPage || null}
                            onChangeSelect={(value: Option) =>
                              setStatusDetailPage(value)
                            }
                            disabled={
                              inquiryDetail?.managed_by?.id > 0 &&
                              user?.user?.admin?.id !==
                                inquiryDetail?.managed_by?.id
                            }
                          />

                          <button
                            type="submit"
                            className="btn btn-primary fs-14 w-25"
                            disabled={
                              statusDetailPage?.value == inquiryDetail?.status
                            }
                            onClick={handleSubmitReply({
                              status: statusDetailPage?.value + "",
                            })}
                          >
                            {t("inquiry.btn.change")}
                          </button>
                        </div>

                        <div className="d-flex gap-4 align-items-center mt-2">
                          <Label
                            className="form-label mb-0"
                            style={{ minWidth: "100px" }}
                          >
                            {t("inquiry.CS_Manager")}
                          </Label>
                          <p className="mb-0">
                            {inquiryDetail?.managed_by?.name || "-"}
                          </p>
                        </div>

                        <div className="d-flex gap-4 align-items-center mt-2">
                          <Label
                            className="form-label mb-0"
                            style={{ minWidth: "100px" }}
                          >
                            {t("inquiry.reply_at")}
                          </Label>
                          <p className="mb-0">
                            {inquiryDetail?.reply_at
                              ? formatDate(
                                  inquiryDetail?.reply_at,
                                  "YYYY.MM.DD HH:mm:ss"
                                )
                              : "-"}
                          </p>
                        </div>
                      </Col>
                    </Row>
                  </div>
                ) : (
                  <div
                    className="d-flex"
                    style={{ gap: "11px", flexDirection: "column" }}
                  >
                    <Row>
                      <Col sm={12} md={5} lg={5}>
                        {/* <div className="d-flex gap-4">
                          <Label className="form-label mb-0">
                            {t("inquiry.service")}
                          </Label>
                          <div
                            className="mb-0"
                            style={{
                              width: "fit-content",
                            }}
                          >
                            {renderService(inquiryDetail?.service_type || "")}
                          </div>
                        </div> */}
                        <div className="d-flex gap-4">
                          <Label
                            className="form-label mb-0"
                            style={{ minWidth: "100px" }}
                          >
                            {t("inquiry.Inquiry_type")}
                          </Label>
                          <div className="mb-0">
                            {t(
                              PUBLIC_INQUIRY_LANG[inquiryDetail?.inquiry_type]
                            )}
                          </div>
                        </div>

                        <div className="d-flex gap-4 align-items-center mt-2">
                          <Label
                            className="form-label mb-0"
                            style={{ minWidth: "100px" }}
                          >
                            {t("inquiry.Individual/Corporation")}
                          </Label>
                          <p className="mb-0">
                            {t(BUSINESS_LANG[inquiryDetail?.business_type])}
                          </p>
                        </div>

                        <div className="d-flex gap-4 align-items-center mt-2">
                          <Label
                            className="form-label mb-0"
                            style={{ minWidth: "100px" }}
                          >
                            {t("inquiry.manager")}
                          </Label>
                          <p className="mb-0">{inquiryDetail?.contact_name}</p>
                        </div>

                        <div className="d-flex gap-4 align-items-center mt-2">
                          <Label
                            className="form-label mb-0"
                            style={{ minWidth: "100px" }}
                          >
                            {t("inquiry.phoneNumber")}
                          </Label>
                          <p className="mb-0">{inquiryDetail?.phone_number}</p>
                        </div>

                        <div className="d-flex gap-4 align-items-center mt-2">
                          <Label
                            className="form-label mb-0"
                            style={{ minWidth: "100px" }}
                          >
                            {t("inquiry.email")}
                          </Label>
                          <p className="mb-0">{inquiryDetail?.email}</p>
                        </div>

                        <div className="d-flex gap-4 align-items-center mt-2">
                          <Label
                            className="form-label mb-0"
                            style={{ minWidth: "100px" }}
                          >
                            {t("inquiry.Created_At")}
                          </Label>
                          <p className="mb-0">
                            {formatDate(
                              inquiryDetail?.created_at,
                              "YYYY.MM.DD HH:mm:ss"
                            )}
                          </p>
                        </div>
                      </Col>

                      <Col sm={12} md={7} lg={7}>
                        <div className="d-flex gap-4 align-items-center">
                          <Label
                            className="form-label mb-0"
                            style={{ minWidth: "100px" }}
                          >
                            {t("inquiry.Status")}
                          </Label>
                          <DropdownOption
                            name="status"
                            dataList={INQUIRY_STATUS_DETAIL_PAGE_OPTIONS || []}
                            className="search-filter-category-type"
                            classNamePrefix="name-prefix"
                            initialValue={statusDetailPage || null}
                            onChangeSelect={(value: Option) =>
                              setStatusDetailPage(value)
                            }
                            disabled={
                              inquiryDetail?.managed_by?.id > 0 &&
                              user?.user?.admin?.id !==
                                inquiryDetail?.managed_by?.id
                            }
                          />
                          <button
                            type="submit"
                            className="btn btn-primary fs-14 w-25"
                            disabled={
                              statusDetailPage?.value == inquiryDetail?.status
                            }
                            onClick={handleSubmitReply({
                              status: statusDetailPage?.value + "",
                            })}
                          >
                            {t("inquiry.btn.change")}
                          </button>
                        </div>

                        <div className="d-flex gap-4 align-items-center mt-2">
                          <Label
                            className="form-label mb-0"
                            style={{ minWidth: "100px" }}
                          >
                            {t("inquiry.CS_Manager")}
                          </Label>
                          <p className="mb-0">
                            {inquiryDetail?.managed_by?.name || "-"}
                          </p>
                        </div>

                        <div className="d-flex gap-4 align-items-center mt-2">
                          <Label
                            className="form-label mb-0"
                            style={{ minWidth: "100px" }}
                          >
                            {t("inquiry.reply_at")}
                          </Label>
                          <p className="mb-0">
                            {inquiryDetail?.reply_at
                              ? formatDate(
                                  inquiryDetail?.reply_at,
                                  "YYYY.MM.DD HH:mm:ss"
                                )
                              : "-"}
                          </p>
                        </div>
                      </Col>
                    </Row>
                  </div>
                )}

                <div className="mt-2">
                  <Label className="form-label">{t("inquiry.detail")}</Label>
                  <p>{inquiryDetail?.content}</p>
                </div>

                <div className="">
                  <Label className="form-label">{t("inquiry.answer")}</Label>
                  <textarea
                    className="form-control"
                    id="reply"
                    rows={5}
                    placeholder={t("inquiry.PlaceholderMemo")}
                    value={textReply}
                    onChange={(e: any) => setTextReply(e.target.value)}
                  />
                </div>
                {/* {inquiryDetail?.managed_by?.id > 0 &&
            user?.user?.admin?.id !== inquiryDetail?.managed_by?.id ? null : ( */}
                <div className="hstack gap-2 mt-3 justify-content-center">
                  <button
                    type="submit"
                    className="btn btn-primary fs-14 w-25"
                    disabled={!textReply}
                    onClick={handleSubmitReply({
                      reply: textReply,
                    })}
                  >
                    {loadingReply ? <Spinner size="sm"></Spinner> : null}
                    {t("inquiry.btn.save")}
                  </button>
                </div>
                {/* )} */}
              </div>
            </CardBody>
          </Card>
        </Container>
        <ToastContainer closeButton={false} limit={1} />
      </div>
    </React.Fragment>
  );
};

export default HomePageInquiryDetail;
