import { ChangeEvent, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";
import { catchAsync } from "helpers/general";
import MediaApi from "api/mediaApi";
import { useFormik } from "formik";
import { Button } from "reactstrap";
import DateTimePickerCustom from "pages/CmsHub/Components/DateTimePicker";
import moment from "moment";
import cmsHubMediaApi from "api/cmsHubMediaApi";
import {
  CONFIG_OPTION_TOAST_ERROR,
  CONFIG_OPTION_TOAST_NORMAL,
} from "common/toast";
import Lightbox from "yet-another-react-lightbox";
import Fullscreen from "yet-another-react-lightbox/plugins/fullscreen";
import Zoom from "yet-another-react-lightbox/plugins/zoom";
import FileViewer from "components/Common/fileViewer";

const Item = ({ value, onChange, onDelete, onClickFile, mode }: any) => {
  const { t } = useTranslation();
  const handleDelete = () => {
    onDelete(value);
  };

  const handleUpdate = (date: any) => {
    onChange({ ...value, seo_info_at: moment(date).format("YYYY-MM-DD") });
  };
  return (
    <div className="row">
      <div className="col-3 d-flex align-items-center gap-3">
        <img src={value?.media?.path} style={{ width: "75%", cursor: 'pointer' }} onClick={onClickFile} />
        {
          mode !== "view" ? <i
            onClick={handleDelete}
            className="ri-delete-bin-6-line"
            style={{ fontSize: "30px", cursor: 'pointer' }}
          ></i> : null
        }
      </div>

      <div className="col-3">
        <label className={`col-sm-4 col-form-label ${mode !== "view" ? "pb-0" : ""} text-nowrap`}>
          {t("media.SEO_measurement_date")}
        </label>
        {
          mode === "view" ? <p>{moment(value?.seo).format("YYYY-MM-DD")}</p> : <DateTimePickerCustom
            date={value?.seo}
            dateFormat="yyyy-MM-dd"
            isNotFilterTime={true}
            isNotshowTimeSelect={true}
            onChange={handleUpdate}
          />
        }
      </div>
      <div className="col-3">
        <label className="col-sm-4 col-form-label text-nowrap">
          {t("media.upload_date")}
        </label>
        <p>{moment(value?.created_at).format("YYYY-MM-DD")}</p>
      </div>
      <div className="col-3">
        <label className="col-sm-4 col-form-label text-nowrap">
          {t("media.uploader")}
        </label>
        <p>{value?.managed_by?.name}</p>
      </div>
    </div>
  );
};

const SEOInformation = ({
  id,
  onClose,
  mediaDetailDefault,
  refetch,
  mode,
}: {
  id: number;
  onClose: () => void;
  mediaDetailDefault: any;
  refetch: any;
  mode?: "view" | "edit";
}) => {
  const [index, setIndex] = useState<any>(-1);
  const { t } = useTranslation();
  const inputRef = useRef<any>();
  const authUser: any = localStorage.getItem("authUser");
  const obj = JSON.parse(authUser);
  const initialValues = {
    seo_infos: mediaDetailDefault?.seoInfos || [],
  };

  const formik = useFormik({
    initialValues,
    enableReinitialize: true,
    onSubmit: async (values) => {
      const formData = new FormData();
      const arr: any[] = [];
      let seo_infos: any[] = [];
      if (values?.seo_infos?.length) {
        values?.seo_infos?.forEach((item: any) => {
          if (item?.uploader) {
            formData.append("files", item?.file);
            formData.append("returned_datas[]", item?.seo_info_at);
          } else {
            arr.push({
              media_id: item?.media_id,
              seo_info_at: item?.seo_info_at || item?.seo,
            });
          }
        });

        if (formData.getAll("files").length) {
          const [errorMedia, resultMedia] = await catchAsync(
            cmsHubMediaApi.cmshubCreateMedia(formData)
          );
          seo_infos = resultMedia?.data?.map((i: any) => ({
            media_id: i?.id,
            seo_info_at: i?.returned_data,
          }));
        }
      }

      const [err, result] = await catchAsync(
        MediaApi.updateCompanies({
          id: mediaDetailDefault?.id,
          data: { seo_infos: [...arr, ...seo_infos] },
        })
      );

      if (result?.statusCode === 200) {
        toast("success", CONFIG_OPTION_TOAST_NORMAL);
        refetch();
        onClose();
      }
      if (err) {
        console.log(err);
        toast(Object.values(err)?.[0] || err, CONFIG_OPTION_TOAST_ERROR);
      }
    },
  });

  return (
    <div className="h-100 d-flex flex-column">
      <form onSubmit={formik.handleSubmit} style={{ width: "100%" }}>
        <div className="d-flex flex-column gap-3">
          {
            mode !== "view" ? <div className="d-flex gap-3 align-items-center">
              <div
                style={{
                  fontSize: 15,
                  lineHeight: "20px",
                  fontWeight: 700,
                }}
              >
                {t("media.previous_SEO_information")}
              </div>

              <Button
                type="button"
                className="d-flex align-items-center justify-content-center"
                style={{ position: "relative", cursor: "pointer" }}
              >
                <input
                  type="file"
                  multiple
                  accept=".jpg,.png,.jpeg"
                  style={{
                    opacity: 0,
                    position: "absolute",
                    width: "100%",
                  }}
                  ref={inputRef}
                  onChange={(event: ChangeEvent<HTMLInputElement>) => {
                    if (
                      event?.target?.files === null ||
                      event.target?.files?.length === 0
                    ) {
                      return;
                    }
                    const allowedExtensions = ["jpg", "jpeg", "png"];
                    const temp = Array.from(event?.target?.files)
                      ?.filter((file) => {
                        const extension = file.name
                          .split(".")
                          .pop()
                          ?.toLowerCase();
                        return allowedExtensions.includes(extension!);
                      })
                      ?.map((i: any, index) => ({
                        id: moment().toISOString() + index,
                        media: {
                          path: URL.createObjectURL(i as any),
                        },
                        created_at: moment().format("YYYY-MM-DD"),
                        seo: moment().format("YYYY-MM-DD"),
                        seo_info_at: moment().format("YYYY-MM-DD"),
                        uploader: obj?.admin?.name || "Admin",
                        file: i,
                        managed_by: {
                          name: obj?.admin?.name || "Admin",
                        },
                      }));
                    const arr = [...formik?.values?.seo_infos, ...temp];

                    formik.setFieldValue("seo_infos", arr);
                  }}
                />
                <div>{t("media.attach_file")}</div>
              </Button>
            </div> : null
          }
          {formik?.values?.seo_infos?.map((item: any, index: number) => (
            <Item
              key={item?.id}
              value={item}
              onChange={(value: any) => {
                const arr: any = [...formik?.values?.seo_infos];
                const index = arr?.findIndex((i: any) => i?.id === value?.id);
                arr[index] = value;
                formik.setFieldValue("seo_infos", arr);
              }}
              onDelete={(value: any) => {
                const arr = [...formik?.values?.seo_infos];
                const temp = arr?.filter((i: any) => i?.id !== value?.id);
                formik.setFieldValue("seo_infos", temp);
                inputRef.current.value = "";
              }}
              mode={mode}
              onClickFile={() => setIndex(index)}
            />
          ))}
        </div>
        <div className="hstack gap-2 mt-5 justify-content-center">
          {
            mode === "view" ? <button
              type="button"
              onClick={() => {
                onClose();
              }}
              className="btn btn-primary mx-auto fs-14 w-25"
            >
              {t("media.Check")}
            </button> : <>
              <button
                className="btn btn-soft-secondary fs-14 w-25"
                type="button"
                color="light"
                onClick={() => onClose()}
              >
                {t("media.Cancel")}
              </button>
              <button
                type="submit"
                disabled={
                  JSON.stringify(mediaDetailDefault?.seoInfos) ===
                  JSON.stringify(formik?.values?.seo_infos)
                }
                className="btn btn-primary fs-14 w-25"
              >
                {t("media.Save")}
              </button>
            </>
          }
        </div>
      </form>
      <Lightbox
        index={index}
        slides={formik?.values?.seo_infos?.map((item: any) => ({ src: item?.media?.path }))}
        open={index >= 0}
        close={() => setIndex(-1)}
        plugins={[Fullscreen, Zoom]}
        zoom={{
          maxZoomPixelRatio: 5,
        }}
      />
    </div>
  );
};

export default SEOInformation;
