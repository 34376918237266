import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import {
  Col,
  FormFeedback,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalHeader,
  Nav,
  NavItem,
  NavLink,
  Row,
  Spinner,
  TabContent,
  TabPane,
} from "reactstrap";
import classnames from "classnames";
import { Formik } from "formik";
import DropdownOption from "components/Common/DropdownOption";
import {
  // BUSSINESS_TEAM_LANG,
  COMPANY_TYPE,
  LAYOUT_TYPE,
  MANAGER_ADS_TYPE,
  TYPE_SCRIPT_OPTIONS,
} from "helpers/constans";
import moment from "moment";
import MemberInformation from "../MemberInformation";
import {
  CONFIG_OPTION_TOAST_ERROR,
  CONFIG_OPTION_TOAST_NORMAL,
} from "common/toast";
import { toast } from "react-toastify";
import { catchAsync } from "helpers/general";
import MediaApi from "api/mediaApi";
import GeneralMemberInformation from "../GeneralMemberInformation";
import DateTimePickerCustom from "pages/CmsHub/Components/DateTimePicker";
import { useSelector } from "react-redux";
import * as Yup from "yup";
import SEOInformation from "../SEOInformation";

interface Props {
  media: any;
  onClose: () => void;
  onRefresh: () => void;
  mode?: "view" | "edit";
}
const MediaModal = ({ media, onClose, onRefresh, mode = "edit" }: Props) => {
  const { t } = useTranslation();
  const [mediaDetail, setMediaDetail] = useState<any>();
  const [tabModal, setTabModal] = useState("1");
  // const [business, setBusinessTeams] = useState<any>([]);
  const { user } = useSelector((state: any) => state?.Login);
  const getDetail = async (id: number) => {
    const [err, result] = await catchAsync(MediaApi.getDetailMedia(id));

    if (err) {
      toast("error", err.message);
      return;
    }
    setMediaDetail(result?.data);
  };

  const mediaSchema = Yup.object().shape({
    domain: Yup.string()
      .matches(
        /^(https?:\/\/)?([a-zA-Z0-9-]+\.)*[a-zA-Z0-9-]+\.[a-zA-Z]{2,6}(\/[\w\.-]*)*\/?$|^(https?:\/\/)$/i,
        t("media.URLFormatIsIncorrect")
      )
      .optional()
      .nullable(),
  });

  // const BUSSINESS = useMemo(() => {
  //   const arr: any =
  //     business?.map((item: any) => ({
  //       value: item.id,
  //       label: t(BUSSINESS_TEAM_LANG[item.id]),
  //     })) || [];
  //   return arr;
  // }, [business]);

  const layoutTypes = [
    {
      value: LAYOUT_TYPE.NEWS,
      label: t("media.news"),
    },
    {
      value: LAYOUT_TYPE.MAGAZINE,
      label: t("media.magazine"),
    },
  ];

  // const businessTeams = async () => {
  //   const [err, result] = await catchAsync(MediaApi.businessTeams());

  //   if (err) {
  //     console.log(err);
  //     return;
  //   }
  //   setBusinessTeams(result?.data);
  // };

  useEffect(() => {
    if (media) {
      getDetail(media.id);
    }
  }, [media]);

  // useEffect(() => {
  //   businessTeams();
  // }, [t]);

  return (
    <Modal
      isOpen={!!media}
      centered={true}
      scrollable={false}
      style={{ minWidth: "900px" }}
    >
      <ModalHeader>
        <Nav
          className="nav-tabs-custom rounded card-header-tabs border-bottom-0 mt-0 gap-3"
          role="tablist"
        >
          <NavItem>
            <NavLink
              className={`cursor-pointer ${classnames({
                active: tabModal === "1",
              })}`}
              onClick={() => {
                setTabModal("1");
              }}
            >
              {t("media.media_information")}
            </NavLink>
          </NavItem>
          <NavItem>
            <NavLink
              className={`cursor-pointer ${classnames({
                active: tabModal === "4",
              })}`}
              onClick={() => {
                setTabModal("4");
              }}
            >
              {t("media.SEO_information")}
            </NavLink>
          </NavItem>
          <NavItem>
            <NavLink
              className={`cursor-pointer ${classnames({
                active: tabModal === "2",
              })}`}
              onClick={() => {
                setTabModal("2");
              }}
            >
              {t("media.MemberInformation")}
            </NavLink>
          </NavItem>
          <NavItem>
            <NavLink
              className={`cursor-pointer ${classnames({
                active: tabModal === "3",
              })}`}
              onClick={() => {
                setTabModal("3");
              }}
            >
              {t("media.GeneralMemberInformation")}
            </NavLink>
          </NavItem>
        </Nav>
      </ModalHeader>
      <ModalBody className="">
        <div>
          <TabContent activeTab={tabModal} className="text-muted">
            <TabPane tabId={"1"} id={"tab-1"}>
              <Formik
                initialValues={{
                  // business_team_id: mediaDetail?.business_team?.id || "",
                  company_name: mediaDetail?.name || "",
                  name: mediaDetail?.admin?.name || "",
                  media_category: mediaDetail?.media_category || "",
                  domain: mediaDetail?.domain || "",
                  phone_number: mediaDetail?.admin?.phone_number || "",
                  script_type: mediaDetail?.srcipt_status?.script_type,
                  domain_start_date: mediaDetail?.domain_start_date || "",
                  domain_end_date: mediaDetail?.domain_end_date || "",
                  layout_type: mediaDetail?.layout_type || LAYOUT_TYPE.NEWS,
                  manager_ads_type:
                    mediaDetail?.manager_ads_type || MANAGER_ADS_TYPE.NONE,
                  domain_provider: mediaDetail?.domain_provider || "",
                  domain_id: mediaDetail?.domain_id || "",
                  domain_password: mediaDetail?.domain_password || "",
                  email: mediaDetail?.email || "",
                  company_type: mediaDetail?.company_type || COMPANY_TYPE.NEW,
                }}
                validationSchema={mediaSchema}
                enableReinitialize
                onSubmit={async (values, { setSubmitting }) => {
                  try {
                    const form: any = {
                      id: mediaDetail?.id,
                      data: {
                        ...values,
                        phone_number: values?.phone_number + "",
                        domain_start_date: values?.domain_start_date
                          ? moment(values?.domain_start_date).format(
                              "YYYY-MM-DD"
                            )
                          : "",
                        domain_end_date: values?.domain_end_date
                          ? moment(values?.domain_end_date).format("YYYY-MM-DD")
                          : "",
                      },
                    };

                    // for (const key in form?.data) {
                    //   if (
                    //     !form?.data?.[key] &&
                    //     typeof form?.data?.[key] !== "number"
                    //   ) {
                    //     delete form?.data?.[key];
                    //   }
                    // }
                    // if (
                    //   !user?.admin?.permissions?.includes("sales_management")
                    // ) {
                    //   delete form?.data?.business_team_id;
                    // }
                    const [err, result] = await catchAsync(
                      MediaApi.updateCompanies(form)
                    );

                    if (result?.statusCode === 200) {
                      toast("success", CONFIG_OPTION_TOAST_NORMAL);
                      onRefresh();
                      onClose();
                    }
                    if (err) {
                      console.log(err);
                      toast(
                        Object.values(err)?.[0] || err,
                        CONFIG_OPTION_TOAST_ERROR
                      );
                    }
                  } catch (error: any) {
                    toast(error?.message || error, CONFIG_OPTION_TOAST_ERROR);
                  } finally {
                    setSubmitting(false);
                  }
                }}
              >
                {({
                  values,
                  errors,
                  handleChange,
                  handleSubmit,
                  setFieldValue,
                  /* and other goodies */
                }) => (
                  <form onSubmit={handleSubmit}>
                    <div
                      style={{
                        fontSize: 15,
                        lineHeight: "20px",
                        fontWeight: 700,
                        marginBottom: "20px",
                      }}
                    >
                      {t("media.MediaInformation")}
                    </div>
                    <Row>
                      {/* <Col lg={6}>
                        <div className="mb-3">
                          <Label htmlFor="business_team_id" className="form-label">
                            {t("media.business")}
                          </Label>
                          <DropdownOption
                            name="business_team_id"
                            dataList={BUSSINESS}
                            className="search-filter-category-type"
                            classNamePrefix="name-prefix"
                            initialValue={BUSSINESS?.find(
                              (i: any) => i?.value == values?.business_team_id
                            )}
                            onChangeSelect={(e: any) => {
                              if (e) {
                                setFieldValue(
                                  "business_team_id",
                                  Number(e?.value)
                                );
                              }
                            }}
                            disabled={
                              !user?.admin?.permissions?.includes(
                                "sales_management"
                              )
                            }
                            labelTop={""}
                          />
                        </div>
                      </Col> */}
                      {/* <Col lg={6}></Col> */}
                      <Col lg={6}>
                        <div className="mb-3">
                          <Label htmlFor="company_name" className="form-label">
                            {t("media.MediaName")}
                          </Label>
                          <Input
                            name="company_name"
                            type="text"
                            className="form-control"
                            onChange={handleChange}
                            maxLength={50}
                            value={values.company_name}
                            readOnly={mode === "view"}
                            invalid={!!errors.company_name}
                          />
                        </div>
                      </Col>
                      <Col lg={6}>
                        <div className="mb-3">
                          <Label htmlFor="company_name" className="form-label">
                            {t("media.subscription_type")}
                          </Label>
                          <Input
                            name="company_name"
                            type="text"
                            className="form-control"
                            onChange={handleChange}
                            maxLength={50}
                            value={
                              values?.company_type === COMPANY_TYPE.NEW
                                ? t("media.new_creation")
                                : t("media.service_transfer")
                            }
                            disabled
                          />
                        </div>
                      </Col>
                      <Col lg={6}>
                        <div className="mb-3">
                          <Label className="form-label">
                            {t("media.Email")}
                          </Label>
                          <Input
                            type="text"
                            className="form-control"
                            disabled
                            value={mediaDetail?.admin?.email || ""}
                            readOnly={mode === "view"}
                            placeholder={t("media.pls_email")}
                            maxLength={50}
                            invalid={!!errors.email}
                          />
                        </div>
                      </Col>
                      <Col lg={6}></Col>
                      <Col lg={6}>
                        <div className="mb-3">
                          <Label htmlFor="name" className="form-label">
                            {t("media.MemberName")}
                          </Label>
                          <Input
                            name="name"
                            type="text"
                            maxLength={50}
                            className="form-control"
                            onChange={handleChange}
                            value={values.name}
                            readOnly={mode === "view"}
                            placeholder={t("media.pls_name")}
                            invalid={!!errors.name}
                          />
                        </div>
                      </Col>
                      <Col lg={6}>
                        <div className="mb-3">
                          <Label htmlFor="phone_number" className="form-label">
                            {t("media.PhoneNumber")}
                          </Label>
                          <Input
                            name="phone_number"
                            type="number"
                            className="form-control"
                            onChange={handleChange}
                            maxLength={50}
                            value={values.phone_number}
                            placeholder={t("media.pls_phone")}
                            readOnly={mode === "view"}
                            invalid={!!errors.phone_number}
                          />
                        </div>
                      </Col>
                      <Col lg={6}>
                        <div className="mb-3">
                          <Label htmlFor="layout_type" className="form-label">
                            {t("media.layout")}
                          </Label>
                          <DropdownOption
                            name="layout_type"
                            dataList={layoutTypes}
                            className="search-filter-category-type"
                            classNamePrefix="name-prefix"
                            initialValue={layoutTypes?.find(
                              (i) => i?.value == values?.layout_type
                            )}
                            disabled
                            onChangeSelect={(e: any) => {
                              if (e) {
                                setFieldValue("layout_type", e?.value);
                              }
                            }}
                            labelTop={""}
                          />
                        </div>
                      </Col>
                      <Col lg={6}></Col>

                      <Col lg={6}>
                        <div className="mb-3 d-flex gap-3">
                          <Label
                            htmlFor="media_category"
                            className="form-label"
                          >
                            {t("media.ServiceSubscriptionDate")}
                          </Label>
                          <p className="m-0">
                            {mediaDetail?.created_at
                              ? moment(mediaDetail?.created_at).format(
                                  "YYYY.MM.DD"
                                )
                              : ""}
                          </p>
                        </div>
                      </Col>

                      <Col lg={6}>
                        {/* <div className="mb-3">
                          <Label className="form-label">
                            {t("media.ScriptStatus")}
                          </Label>
                          <DropdownOption
                            name="service_type"
                            dataList={TYPE_SCRIPT_OPTIONS?.map((item: any) => ({
                              value: item?.value,
                              label: t(item?.label),
                            }))}
                            className="search-filter-category-type"
                            classNamePrefix="name-prefix"
                            initialValue={TYPE_SCRIPT_OPTIONS.map(
                              (item: any) => ({
                                value: item?.value?.toString(),
                                label: t(item?.label),
                              })
                            )?.find(
                              (i) => i.value === values?.script_type?.toString()
                            )}
                            onChangeSelect={(e: any) => {
                              if (e) {
                                setFieldValue("script_type", e?.value);
                              }
                            }}
                            labelTop={""}
                            readOnly={mode === "view"}
                          />
                          {mediaDetail?.srcipt_status?.updated_at ? (
                            <div className="d-flex text-primary">
                              <p className="m-0">
                                {moment(
                                  mediaDetail?.srcipt_status?.updated_at
                                ).format("YYYY.MM.DD a hh:mm")}
                              </p>
                              <p className="m-0">
                                _{mediaDetail?.srcipt_status?.updated_by}
                              </p>
                            </div>
                          ) : null}
                        </div> */}
                      </Col>
                    </Row>
                    <Row>
                      <div
                        style={{
                          fontSize: 15,
                          lineHeight: "20px",
                          fontWeight: 700,
                          marginBottom: "20px",
                        }}
                      >
                        {t("media.domain_information_2")}
                      </div>
                      <Col lg={6}>
                        <div className="mb-3">
                          <Label
                            htmlFor="domain_provider"
                            className="form-label"
                          >
                            {t("media.domain_purchase_company")}
                          </Label>
                          <Input
                            name="domain_provider"
                            type="text"
                            className="form-control"
                            onChange={handleChange}
                            maxLength={255}
                            value={values.domain_provider}
                            placeholder={t("media.pls_domain_purchase_company")}
                            readOnly={mode === "view"}
                            invalid={!!errors.domain_provider}
                          />
                        </div>
                      </Col>
                      <Col lg={6}></Col>
                      <Col lg={6}>
                        <div className="mb-3">
                          <Label htmlFor="domain_id" className="form-label">
                            ID
                          </Label>
                          <Input
                            name="domain_id"
                            type="text"
                            className="form-control"
                            onChange={handleChange}
                            maxLength={255}
                            value={values.domain_id}
                            placeholder={t(
                              "media.pls_domain_purchase_company_id"
                            )}
                            readOnly={mode === "view"}
                            invalid={!!errors.domain_id}
                          />
                        </div>
                      </Col>
                      <Col lg={6}>
                        <div className="mb-3">
                          <Label
                            htmlFor="domain_password"
                            className="form-label"
                          >
                            PW
                          </Label>
                          <Input
                            name="domain_password"
                            type="text"
                            className="form-control"
                            onChange={handleChange}
                            maxLength={255}
                            value={values.domain_password}
                            placeholder={t(
                              "media.pls_domain_purchase_company_password"
                            )}
                            readOnly={mode === "view"}
                            invalid={!!errors.domain_password}
                          />
                        </div>
                      </Col>
                      <Col lg={6}>
                        <div className="mb-3">
                          <Label htmlFor="domain" className="form-label">
                            {t("media.DomainURL")}
                          </Label>
                          <Input
                            name="domain"
                            type="text"
                            maxLength={50}
                            className="form-control"
                            onChange={handleChange}
                            value={values.domain}
                            invalid={!!errors.domain}
                            readOnly={mode === "view"}
                          />
                          <FormFeedback>{errors.domain as string}</FormFeedback>
                        </div>
                      </Col>
                      <Col lg={6}></Col>
                      <Col lg={6}>
                        <div className="mb-3">
                          <Label
                            htmlFor="domain_start_date"
                            className="form-label"
                          >
                            {t("media.DomainStartDate")}
                          </Label>
                          <DateTimePickerCustom
                            isNotFilterTime={true}
                            isNotshowTimeSelect={true}
                            className="w-100"
                            wrapperClassName="w-100"
                            placeholder="----:--:--"
                            dateFormat="yyyy-MM-dd"
                            date={values?.domain_start_date}
                            readOnly={mode === "view"}
                            onChange={(date) => {
                              setFieldValue("domain_start_date", date);
                              if (
                                moment(date) > moment(values?.domain_end_date)
                              ) {
                                setFieldValue("domain_end_date", null);
                                return;
                              }
                            }}
                          />
                        </div>
                      </Col>

                      <Col lg={6}>
                        <div className="mb-3">
                          <Label
                            htmlFor="domain_end_date"
                            className="form-label"
                          >
                            {t("media.DomainExpirationDate")}
                          </Label>
                          <DateTimePickerCustom
                            isNotFilterTime={true}
                            isNotshowTimeSelect={true}
                            className="w-100"
                            wrapperClassName="w-100"
                            dateFormat="yyyy-MM-dd"
                            placeholder="----:--:--"
                            readOnly={mode === "view"}
                            date={values?.domain_end_date}
                            onChange={(date) => {
                              setFieldValue("domain_end_date", date);
                            }}
                            minDate={values?.domain_start_date}
                          />
                        </div>
                      </Col>
                    </Row>
                    {mode === "edit" ? (
                      <div className="hstack gap-2 mt-5 justify-content-center">
                        <button
                          className="btn btn-soft-secondary fs-14 w-25"
                          type="button"
                          color="light"
                          onClick={() => onClose()}
                        >
                          {t("media.Cancel")}
                        </button>
                        <button
                          type="submit"
                          className="btn btn-primary fs-14 w-25"
                        >
                          {false ? <Spinner size="sm me-2"></Spinner> : null}
                          {t("media.Save")}
                        </button>
                      </div>
                    ) : (
                      <div className="hstack gap-2 mt-5 justify-content-center">
                        <button
                          type="button"
                          onClick={() => onClose()}
                          className="btn btn-primary fs-14 w-25"
                        >
                          {t("media.Check")}
                        </button>
                      </div>
                    )}
                  </form>
                )}
              </Formik>
            </TabPane>
            <TabPane tabId={"2"} id={"tab-2"}>
              <MemberInformation
                refresh={() => {
                  onRefresh();
                  setTabModal("1");
                  onClose();
                }}
                id={mediaDetail?.id}
                mediaDetailDefault={mediaDetail}
                onClose={() => {
                  setTabModal("1");
                  onClose();
                }}
                refetch={() => {
                  getDetail(media.id);
                }}
                mode={mode}
              />
            </TabPane>
            <TabPane tabId={"3"} id={"tab-3"}>
              <GeneralMemberInformation
                id={mediaDetail?.id}
                mediaDetailDefault={mediaDetail}
                onClose={() => {
                  setTabModal("1");
                  onClose();
                }}
                refetch={() => {
                  getDetail(mediaDetail.id);
                }}
                mode={mode}
              />
            </TabPane>
            <TabPane tabId={"4"} id={"tab-4"}>
              <SEOInformation
                id={mediaDetail?.id}
                mediaDetailDefault={mediaDetail}
                onClose={() => {
                  setTabModal("1");
                  onClose();
                }}
                refetch={() => {
                  getDetail(mediaDetail.id);
                }}
                mode={mode}
              />
            </TabPane>
          </TabContent>
        </div>
      </ModalBody>
    </Modal>
  );
};

export default MediaModal;
