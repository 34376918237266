import en from "date-fns/locale/en-US";
import ko from "date-fns/locale/ko";
import moment from "moment";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { registerLocale } from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { useTranslation } from "react-i18next";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import {
  Card,
  CardBody,
  CardHeader,
  Col,
  Input,
  Row,
  Spinner,
} from "reactstrap";
import { useLocation } from "react-router-dom";
import { useSelector } from "react-redux";
import CountUp from "react-countup";
//
import DatePickerCustom from "components/Common/DatePickerCustom";
import DropdownOption from "components/Common/DropdownOption";
import LabelWrapper from "components/Common/LabelWrapper";
import TableContainer from "components/Common/TableContainer";
import useQueryParams from "components/Hooks/useQueryParams";
import { catchAsync, formatNumber } from "helpers/general";
import cmsHubStatistics from "api/cmsHubStatistics";
import { IPagination } from "api/types/general";

registerLocale("en", en);
registerLocale("ko", ko);

const TYPE_SELECT_DEFAULT: string = "date";

const KeywordInflow = () => {
  const { t, i18n } = useTranslation();
  const companies = useSelector((state: any) => state?.Company?.companies);
  const defaultParams = {
    sort_by: TYPE_SELECT_DEFAULT,
    order_by: `DESC`,
    page: 1,
    page_size: 20,
    search: "",
    start_date: moment().subtract(7, "day").format("YYYY-MM-DD"),
    end_date: moment(new Date()).format("Y-MM-DD"),
    company_id: "",
  };
  const [company_id, setCompanyId] = useState<any>();
  const { getQueryParams, clearQueryParams, setQueryParams } =
    useQueryParams(defaultParams);
  const queryParams = getQueryParams();
  const location = useLocation();
  const [rows, setRows] = useState<Array<any>>([]);
  const [pagination, setPagination] = useState({} as IPagination);

  const [loading, setLoading] = useState(false);
  const [dateSearch, setDateSearch] = useState<any[]>([
    moment(queryParams?.start_date || "", "Y-MM-DD").toDate(),
    moment(queryParams?.end_date || "", "Y-MM-DD").toDate(),
  ]);
  const [titleSearch, setTitleSearch] = useState<string>(
    (queryParams?.title as string) || ""
  );
  const [startDate, endDate] = dateSearch;

  const companyOptions = useMemo(() => {
    return (
      companies?.map((item: any) => ({
        value: item?.id,
        label: item?.name,
      })) || []
    );
  }, [companies]);

  const searchData = () => {
    setQueryParams({
      ...queryParams,
      search: titleSearch,
      company_id,
      page: 1,
      start_date: startDate
        ? moment(startDate).format("YYYY-MM-DD")
        : moment().subtract(1, "year").format("YYYY-MM-DD"),
      end_date: endDate
        ? moment(endDate).format("YYYY-MM-DD")
        : moment().format("YYYY-MM-DD"),
    });
  };

  const resetData = () => {
    clearQueryParams();
    setDateSearch([moment().subtract(7, "days").toDate(), moment().toDate()]);
    setTitleSearch("");
    setCompanyId(null);
  };

  // Column
  const columns = useMemo(
    () => [
      {
        Header: t("cms_statistics.STT"),
        accessor: "id",
        filterable: false,
        sortable: false,
        thWidth: 100,
        Cell: ({ row }: any) => (
          <div>
            {pagination.page_size * (pagination.page - 1) +
              (row?.index + 1 || 0)}
          </div>
        ),
      },
      {
        Header: t("cms_statistics.Keyword"),
        accessor: "keyword",
        filterable: false,
        sortable: false,
        thWidth: 500,
        Cell: (cell: any) => (
          <>
            <div
              className="ellipsis-1"
              style={{ minWidth: "140px", maxWidth: "500px" }}
            >
              {cell.value ? cell?.value : "-"}
            </div>
          </>
        ),
      },
      {
        Header: "PV",
        accessor: "pv",
        filterable: false,
        sortable: false,
        thClass: "text-center",
        Cell: (cell: any) => (
          <>
            <div className="text-center">{formatNumber(cell?.value)}</div>
          </>
        ),
      },
      {
        Header: "UV",
        accessor: "uv",
        filterable: false,
        sortable: false,
        thClass: "text-center",
        Cell: (cell: any) => (
          <>
            <div className="text-center">{formatNumber(cell?.value)}</div>
          </>
        ),
      },
    ],
    [i18n?.language, pagination]
  );

  const handleChangePage = (page: any) => {
    setQueryParams({
      ...queryParams,
      page: page + 1,
    });
  };

  const handleChangePicker = (values: any[] = []) => {
    setDateSearch((_prev: any) => values);
  };

  const paging = async () => {
    const [err, result] = await catchAsync(
      cmsHubStatistics.getArticlesKeywordInflow(queryParams),
      setLoading
    );
    if (err) {
      console.log(err);
      return;
    }
    const { items, pagination } = result?.data;
    setRows(items);
    setPagination(pagination);
  };

  useEffect(() => {
    paging();
    if (queryParams?.search) {
      setTitleSearch(queryParams.search as string);
    }
  }, [location.search]);

  return (
    <React.Fragment>
      <Row>
        <Col lg={12}>
          <Card id="leadsList">
            <CardHeader className="border-0">
              <div className="d-flex flex-column flex-md-row g-4 align-items-start align-items-md-center mb-2 mt-2">
                <Card
                  className="card-animate mb-0 me-0 me-md-4 mt-3 bg-primary-subtle text-primary border-0"
                  style={{ width: "200px" }}
                >
                  <CardBody>
                    <div className="d-flex align-items-center ">
                      <div className="flex-grow-1 overflow-hidden">
                        <p className="text-uppercase fw-medium text-primary text-truncate mb-0">
                          {t("Total")}
                        </p>
                      </div>
                    </div>
                    <div className="d-flex align-items-end justify-content-between mt-2 pt-1">
                      <div>
                        <h4 className="fs-22 fw-semibold ff-secondary mb-0">
                          <span className="counter-value text-primary">
                            {loading ? (
                              <Spinner size="sm"></Spinner>
                            ) : (
                              <CountUp
                                start={0}
                                end={pagination?.total || 0}
                                duration={1}
                              />
                            )}
                          </span>
                        </h4>
                      </div>
                    </div>
                  </CardBody>
                </Card>
                <div className="w-100">
                  <Row className="g-4 align-items-center mb-2 mt-2">
                    <Col sm={12} lg={3} className="mt-2">
                      <LabelWrapper
                        label={`${t("Media")}`}
                        isShow={!!company_id}
                      >
                        <DropdownOption
                          name="type"
                          dataList={companyOptions || []}
                          className="search-filter-category-type"
                          classNamePrefix="name-prefix"
                          initialValue={
                            companyOptions?.find(
                              (item: any) => item.value == company_id
                            ) || {
                              label: t("All"),
                              value: "0",
                            }
                          }
                          onChangeSelect={(event: any) => {
                            // console.log(event?.value);
                            // setQueryParams({
                            //   ...queryParams,
                            //   company_id: event?.value,
                            // });
                            setCompanyId(event?.value);
                          }}
                          isHasOptionAll={true}
                          optionAll={{
                            label: t("All"),
                            value: "0",
                          }}
                        />
                      </LabelWrapper>
                    </Col>
                    <Col
                      sm={12}
                      lg={3}
                      className="date-picker-wrapper-custom mt-3 mt-md-2"
                    >
                      <LabelWrapper
                        label={`${t("Date")}`}
                        isShow={!!startDate || !!endDate}
                      >
                        <DatePickerCustom
                          placeholder={`${t("Start Date")} - ${t("End Date")}`}
                          startDate={startDate || null}
                          endDate={endDate || null}
                          onChangePicker={handleChangePicker}
                          isClearable={true}
                        />
                      </LabelWrapper>
                    </Col>
                  </Row>
                  <Row className="g-4 align-items-center mt-0">
                    <Col sm={12} lg={6} className="mt-2">
                      <LabelWrapper
                        label={`${t("Title Post")}`}
                        isShow={!!titleSearch}
                      >
                        <Input
                          type="text"
                          className="form-control search"
                          placeholder={`${t("Title Post")}...`}
                          value={titleSearch}
                          onChange={(e) => setTitleSearch(e.target.value)}
                          onKeyDown={(e) => {
                            if (e.key === "Enter") {
                              searchData();
                            }
                          }}
                        />
                      </LabelWrapper>
                    </Col>
                    <Col
                      sm={12}
                      lg={6}
                      className="hstack gap-1 justify-content-sm-center justify-content-md-start mt-4 mt-md-2"
                    >
                      <div>
                        <button
                          type="button"
                          className="btn btn-primary me-1"
                          onClick={searchData}
                        >
                          <i className="ri-search-line align-bottom me-1"></i>{" "}
                          {t("Button Search")}
                        </button>
                        <button
                          type="button"
                          className="btn btn-secondary fs-14"
                          onClick={resetData}
                        >
                          <i className="ri-refresh-line align-bottom me-1"></i>{" "}
                          {t("Button Reset")}
                        </button>
                      </div>
                    </Col>
                  </Row>
                </div>
              </div>
            </CardHeader>
            <CardBody className="pt-3">
              <div>
                <TableContainer
                  columns={columns}
                  data={rows || []}
                  customPageSize={pagination?.page_size}
                  customPageIndex={pagination?.page - 1}
                  totalRecords={pagination?.total}
                  customPageCount={Math.ceil(
                    pagination?.total / pagination?.page_size
                  )}
                  className="custom-header-css"
                  divClass="table-responsive table-card"
                  tableClass="align-middle"
                  theadClass="table-light align-middle"
                  handleChangePage={handleChangePage}
                  isLoading={loading}
                />
              </div>
              <ToastContainer closeButton={false} limit={1} />
            </CardBody>
          </Card>
        </Col>
      </Row>
    </React.Fragment>
  );
};

export default KeywordInflow;
